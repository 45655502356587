
let recievedStates;
initStateManager();

export function initStateManager(){
    
    if(!window.statesManager) {
        window.statesManager = {
            states: {},
            setState,
        };
    }

    // localStorage.clear();
    recievedStates = JSON.parse(localStorage.getItem('states')) || null;

}

function setState(name = '', newValue, saveData = false, pushCallbacks = []){
    const changes = {
        type: 'add',
        prevValue: undefined,
        newValue: newValue
    }

    if (window.statesManager.states.hasOwnProperty(name)) {
        changes.type = 'update';
        changes.prevValue = window.statesManager.states[name].value;
        changes.newValue = newValue;

        Object.defineProperty(window.statesManager.states[name], 'value', {
            value: newValue,
            writable: false,
            configurable: true,
            enumerable: true,
        });

        // if(window.statesManager.states[name].saveData != saveData) console.error('You can not change saveData property');
        pushCallbacks.forEach( callback => window.statesManager.states[name].callbacks.push(callback));
    }
    else {
        Object.defineProperty(window.statesManager.states, name, {
            value: {},
            writable: false,
            enumerable: true,
        });

        if (recievedStates && recievedStates[name]) {
            newValue = recievedStates[name].value
            changes.newValue = newValue;
        }

        Object.defineProperties(window.statesManager.states[name], {
            value: {
                value: newValue,
                writable: false,
                configurable: true,
                enumerable: true,
            },
            callbacks: {
                value: pushCallbacks,
                writable: false,
                enumerable: true,
            },
            saveData: {
                value: saveData,
                writable: false,
                enumerable: true,
            },
        });

        // console.trace(setState);
    }

    const dataToTransfer = {}
    Object.keys(window.statesManager.states).forEach(sName => {
        if (window.statesManager.states[sName].saveData) dataToTransfer[sName] = window.statesManager.states[sName];
    });
    
    localStorage.setItem('states', JSON.stringify(dataToTransfer));

    window.statesManager.states[name].callbacks.forEach(callback => callback(changes));

}

//Example
window.statesManager.setState('sound', false, true, [sound]);
// window.statesManager.setState('sound', true);

function sound(changes){
    const state = changes.newValue;
}
