import gsap from "gsap";
import * as THREE from "three";

export const animations = {
	initHandler: initHandler,
	update: update,

	importedAnimPlay: importedAnimPlay,
	importedAnimStop: importedAnimStop,
	importedAnimPaused: importedAnimPaused,

	randomGlow: randomGlow,
	fadeIn: fadeIn,
	fadeOut: fadeOut,
	animatedMaterialAppear: animatedMaterialAppear,
	highlightObj: highlightObj,

    goInside: goInside,
};

let mixer, clips, scene;
function initHandler(pScene) {
    scene = pScene;
	mixer = new THREE.AnimationMixer(scene);
	clips = scene.animations;
}

function update(deltaTime) {
	mixer.update(deltaTime);
}

function importedAnimPlay({
	clipNameIncludes = "",
	callbackOfssetS = 0,
	loop = THREE.LoopOnce,
	onComplete = () => {},
} = {}) {
	const frameClips = clips.filter((clip) => clip.name.includes(clipNameIncludes));
	frameClips.forEach((clip) => {
		const action = mixer.clipAction(clip);
		action.paused = false;
		action.loop = loop;
		action.play();
	});

	const durationS = frameClips[0].duration / mixer.timeScale;
	
	const delayedCall = gsap.delayedCall(durationS + callbackOfssetS, () => {
		onComplete();
	});
	delayedCall.vars.id = clipNameIncludes;
}

function importedAnimStop(clipNameIncludes = "") {
	const frameClips = clips.filter((clip) => clip.name.includes(clipNameIncludes));
	frameClips.forEach((clip) => mixer.clipAction(clip).stop());
	gsap.getById(clipNameIncludes)?.kill();
}

function importedAnimPaused(clipNameIncludes = "", paused) {
	const frameClips = clips.filter((clip) => clip.name.includes(clipNameIncludes));
	frameClips.forEach((clip) => (mixer.clipAction(clip).paused = paused));
	gsap.getById(clipNameIncludes)?.paused(paused);
}

function randomGlow({
	obj,
	minIntensity = 0,
	maxIntensity = 1,
	minDuration = 0.5,
	maxDuration = 1,
} = {}) {
	const to = THREE.MathUtils.randFloat(minIntensity, maxIntensity);
	const duration = THREE.MathUtils.randFloat(minDuration, maxDuration);
	gsap.to(obj.material, {
		emissiveIntensity: to,
		duration: duration,
		ease: "power1.inOut",
		onComplete: () => randomGlow({ 
			obj: obj,
			minIntensity: minIntensity,
			maxIntensity: maxIntensity,
			minDuration: minDuration,
			maxDuration: maxDuration,
		}),
	});
}

function fadeIn({ duration = 1, onComplete } = {}) {
	gsap.to(container3D.foreground.style, {
		opacity: 0,
		duration: duration,
		ease: "power1.inOut",
		onComplete: onComplete,
	});
}

function fadeOut({ duration = 1, onComplete } = {}) {
	gsap.to(container3D.foreground.style, {
		opacity: 1,
		duration: duration,
		ease: "power1.inOut",
		onComplete: onComplete,
	});
}

function animatedMaterialAppear({ material, duration, maxOpacity = 0.6, fadePercentage = .2 } = {}) {
	maxOpacity = Math.round(maxOpacity * 255).toString(16);
	const maxOpacityHEX = `#${maxOpacity}${maxOpacity}${maxOpacity}`;

	const colorFadeIn = {
		from: "#000000",
		to: maxOpacityHEX,
	}; 
	const colorFadeOut = {
		from: maxOpacityHEX,
		to: "#000000",
	};
	const tl = gsap.timeline({ repeat: -1 });
	tl.to(colorFadeIn, {
		from: colorFadeIn.to,
		duration: duration * fadePercentage,
		onUpdate: () => {
			material.color = new THREE.Color(colorFadeIn.from);
		},
	}).to(colorFadeOut, {
		from: colorFadeOut.to,
		delay: duration * (1 - fadePercentage * 2),
		duration: duration * fadePercentage,
		onUpdate: () => {
			material.color = new THREE.Color(colorFadeOut.from);
		},
	});
}

function highlightObj(obj, selected) {
	gsap.to(obj.material, {
		emissiveIntensity: selected ? 3 : 1,
		duration: 0.25,
		ease: "power1.inOut",
	});
}

function goInside({ controls, locMesh, onComplete = () => {} } = {}){

    document.body.style.pointerEvents = 'none';

    controls.enabled = false;
    //Center the camera controls on a workshop
    gsap.to(controls._target, { 
        x: locMesh.position.x, 
        y: locMesh.position.y, 
        z: locMesh.position.z, 
        duration: 2.2, 
        ease: "power1.inOut",
        onUpdate: () => controls.update(1/60),
    });
    //Zoom the camera controls on a workshop
    gsap.to(controls, { 
        _zoom: 1.5, 
        duration: 2.2, 
        ease: "power1.inOut",
        onUpdate: () => controls.update(1/60),
    });
    //Fade out
    gsap.to(container3D.foreground.style, { 
        opacity: 1, 
        duration: 2, 
        delay: .2,
        ease: "power1.in",
        onComplete: () => {
            controls.enabled = true;
            onComplete();
            document.body.style.pointerEvents = '';
        }
    });
}

